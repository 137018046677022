<template>
  <div v-if="loading">
    <Loader />
  </div>
  <div class="card" v-if="!loading" style="background-color: transparent">
    <div v-if="$route.query.id" class="row mb-5">
      <div class="col-12">
        <button
          v-if="form.status !== '0'"
          type="button"
          class="btn btn-light shadow-sm"
          style="font-size: 14px; font-weight: bold; color: black"
          @click="goBack()"
        >
          กลับ
        </button>
      </div>
    </div>
    <button
      type="button"
      class="btn btn-primary"
      id="button-open-dialog"
      data-bs-toggle="modal"
      data-bs-target="#kt_modal_1"
      hidden
    ></button>
    <div class="modal fade" tabindex="-1" id="kt_modal_1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">เพิ่มจำนวนสินค้า</h5>

            <!--begin::Close-->
            <div class="d-flex justify-content-between">
              <h5 class="modal-title"></h5>
              <button
                data-bs-dismiss="modal"
                @click="close"
                type="button"
                class="btn btn-sm"
              >
                <i
                  class="bi bi-x m-0 p-0"
                  id="close-btn"
                  style="font-size: 25px"
                ></i>
              </button>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body">
            <label
              for="validationTooltip01"
              class="required form-label col-sm-10"
              >ระบุจำนวนสินค้า</label
            >
            <input
              ref="refamount"
              class="form-control"
              v-model="amount"
              type="text"
              max-length="4"
              v-mask-number
              required
              @keypress.enter.prevent="saveProduct(amount)"
            />
          </div>

          <div class="modal-footer">
            <!-- <button
              type="button"
              class="btn btn-light"
              data-bs-dismiss="modal"
            >
              Close
            </button> -->
            <button
              type="button"
              class="btn btn-primary"
              data-bs-dismiss="modal"
              @click="saveProduct(amount)"
            >
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- modal -->

    <form id="formItems" class="needs-validation" novalidate>
      <!-- form -->
      <div class="row">
        <div class="col-sm-8 mb-4">
          <div class="card shadow-sm mb-4 classcustomer">
            <div class="card-body">
              <div class="col-sm-12">
                <div class="input-group mb-3">
                  <div
                    class="input-group-prepend"
                    :style="isView ? '' : 'cursor: pointer'"
                    @click="openDialogSearchCustomer()"
                    :disabled="isView"
                  >
                    <span
                      class="input-group-text"
                      id="basic-addon1"
                      style="border-radius: 5px 0px 0px 5px"
                      ><i
                        class="bi bi-person-plus-fill"
                        style="font-size: 22px"
                      ></i
                    ></span>
                  </div>
                  <input
                    v-model="searchCustomer"
                    type="text"
                    class="form-control"
                    :placeholder="$t(`findbuyer`)"
                    aria-describedby="basic-addon2"
                    :disabled="isView"
                    list="datalistCustomers"
                    @keypress.enter="saveCustomer()"
                  />
                  <!-- @keypress.enter.prevent="addCustomer()" -->
                  <datalist
                    id="datalistCustomers"
                    v-if="searchListCustomer.length >= 1"
                  >
                    <option
                      id="datalistCustomer11"
                      v-for="(item, index) in searchListCustomer"
                      :key="index"
                      :value="item.code"
                    >
                      {{ item.name }}
                      <hr style="color: LightGrey" class="mt-4 mb-0 pb-0" />
                    </option>
                  </datalist>
                  <button
                    type="button"
                    :disabled="isView ? true : loadingAddCs ? true : false"
                    class="btn btn-light btn-primary"
                    style="font-weight: bold"
                    @click="saveCustomer()"
                  >
                    <!-- @click="addCustomer()" -->
                    <div
                      v-if="loadingAddCs"
                      class="spinner-grow spinner-grow-sm text-light"
                      role="status"
                    ></div>
                    เลือก
                  </button>
                </div>
              </div>

              <!-- <div v-if="this.customerDetails" class="col-sm-12 mt-6">
                <img
                  src="media/svg/brand-logos/atica.svg"
                  style="width: 30px"
                />
              </div> -->

              <div class="row" v-if="customerDetails">
                <div class="col-sm-6 mt-4">
                  <span style="font-size: 14px; font-weight: 600">
                    {{ $t("customername") }}
                  </span>
                  <br />
                  <span
                    style="font-size: 14px; font-weight: 400; color: #7e8299"
                    >คุณ{{ customerDetails.name }}</span
                  >
                </div>

                <div class="col-sm-6 mt-4">
                  <span style="font-size: 14px; font-weight: 600">
                    {{ $t("route") }}
                  </span>
                  <br />
                  <span
                    style="font-size: 14px; font-weight: 400; color: #7e8299"
                    >{{ customerDetails.route.code }} |
                    {{ customerDetails.route.name }}</span
                  >
                </div>
                <div class="col-sm-6 mt-4">
                  <span style="font-size: 14px; font-weight: 600">
                    {{ $t("businesstype") }}
                  </span>
                  <br />
                  <span
                    style="font-size: 14px; font-weight: 400; color: #7e8299"
                    >{{ customerDetails.business_type }}</span
                  >
                </div>

                <div class="col-sm-6 mt-4">
                  <span style="font-size: 14px; font-weight: 600">
                    {{ $t("creditperiod") }}
                  </span>
                  <br />
                  <span
                    style="font-size: 14px; font-weight: 400; color: #7e8299"
                    >{{ customerDetails.credit_period }}</span
                  >
                </div>
                <div class="col-sm-6 mt-4">
                  <span style="font-size: 14px; font-weight: 600">
                    {{ $t("limitcredit") }}
                  </span>
                  <br />
                  <span
                    style="font-size: 14px; font-weight: 400; color: #7e8299"
                    >{{ customerDetails.credit_limit }} บาท</span
                  >
                </div>
                <div class="col-sm-6 mt-4">
                  <span style="font-size: 14px; font-weight: 600">
                    {{ $t("balancecredit") }}
                  </span>
                  <br />
                  <span
                    style="font-size: 14px; font-weight: 400; color: #7e8299"
                    >{{ customerDetails.credit_balance }} บาท
                  </span>
                </div>
                <div class="col-sm-6 mt-4" v-if="form.van">
                  <span style="font-size: 14px; font-weight: 600">
                    {{ $t("van") }}
                  </span>
                  <br />
                  <span
                    style="font-size: 14px; font-weight: 400; color: #7e8299"
                    >{{ form.van.van_name }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <!-- วาป -->
          <div class="card shadow-sm classproduct mb-4" v-if="customerDetails">
            <div class="card-body">
              <!-- -->
              <div class="row text-sm-start d-flex justify-content-center mb-4">
                <!-- <hr style="color: LightGrey" class="mt-7 mb-0 pb-0" /> -->
                <!-- แบ่ง -->
                <div class="col-sm-12">
                  <div class="input-group mb-3">
                    <div
                      class="input-group-prepend"
                      style="cursor: pointer"
                      @click="openDialogSearchProcuct()"
                    >
                      <span
                        class="input-group-text"
                        id="basic-addon1"
                        style="border-radius: 5px 0px 0px 5px"
                        ><i class="bi bi-search" style="font-size: 21px"></i
                      ></span>
                    </div>

                    <input
                      v-model="searchProduct"
                      type="text"
                      class="form-control"
                      :placeholder="$t(`finditemcode`)"
                      aria-describedby="basic-addon1"
                      list="datalistOptions"
                      disabled
                    />
                    <!-- @keypress.enter.prevent="enterdialog()" -->
                    <datalist
                      id="datalistOptions"
                      v-if="searchListProduct.length >= 1"
                    >
                      <option
                        id="datalistOptions11"
                        v-for="(item, index) in searchListProduct"
                        :key="index"
                        :value="item.code"
                      >
                        {{ item.name }}
                        <hr style="color: LightGrey" class="mt-4 mb-0 pb-0" />
                      </option>
                    </datalist>
                    <!-- @keypress.enter.prevent="enterdialog()" -->
                    <button
                      type="button"
                      class="btn btn-light btn-primary"
                      style="font-weight: bold"
                      data-bs-toggle="modal"
                      data-bs-target="#kt_modal_1"
                      :disabled="oldstatus == 'ยกเลิก' || oldstatus == 'ออกบิล'"
                    >
                      <div
                        v-if="loadingAddPd"
                        class="spinner-grow spinner-grow-sm text-light"
                        role="status"
                      ></div>
                      <!-- เปิดdialog -->
                      <!-- เพิ่ม -->
                      {{ $t("choose") }}
                    </button>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="table-responsive">
                  <table
                    class="table table-hover table-rounded table-striped border gy-7 gs-7"
                  >
                    <thead>
                      <tr
                        style="width: 100%"
                        class="fw-bold fs-5 text-gray-800 border-bottom-2 border-gray-200 mw-200px"
                      >
                        <th style="width: 15%" class="text-center">
                          {{ $t("no") }}
                        </th>
                        <th style="width: 15%" class="text-center">
                          {{ $t("productcode") }}
                        </th>
                        <th style="width: 15%" class="text-center">
                          {{ $t("productname") }}
                        </th>
                        <th style="width: 15%" class="text-center">
                          {{ $t("quantity") }}
                        </th>
                        <th style="width: 15%" class="text-center">
                          {{ $t("sellprice") }}
                        </th>
                        <th style="width: 15%" class="text-center">
                          {{ $t("unitname") }}
                        </th>
                        <th style="width: 10%"></th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr
                        v-for="(item, index) in productItems"
                        :key="index"
                        data-toggle="tooltip"
                        :title="item.searchBy"
                        class="text-center"
                      >
                        <td>{{ index + 1 }}</td>
                        <td>
                          {{ item.item_code }}
                          <!-- <input
                            v-model="item.item_code"
                            class="
                              form-control form-control-solid
                              text-end
                              rounded-3
                            "
                            type="number"
                            disabled
                            min="0"
                            oninput="if(Number(this.value) < Number(this.min)) this.value = this.min;"
                          /> -->
                        </td>
                        <td>{{ item.item_name }}</td>
                        <td>
                          <input
                            v-model="item.item_amt"
                            class="form-control text-end rounded-3"
                            type="number"
                            min="0"
                            oninput="if(Number(this.value) < Number(this.min)) this.value = this.min;"
                            @blur="addQtyByIndex(index, item.item_amt)"
                            @keypress.enter="sumPayout()"
                            :disabled="
                              oldstatus == 'ยกเลิก' || oldstatus == 'ออกบิล'
                            "
                          />
                        </td>

                        <td>
                          {{ item.item_price }}
                          <!-- <input
                            v-model="item.item_price"
                            class="form-control text-end"
                            type="number"
                            min="0"
                            oninput="if(Number(this.value) < Number(this.min)) this.value = this.min;"
                          /> -->
                        </td>
                        <td>{{ item.item_unit }}</td>

                        <td>
                          <div class="row">
                            <div class="col-sm-12 text-end">
                              <button
                                type="button"
                                class="btn btn-link btn-color-muted btn-active-color-danger m-0 p-0"
                                @click="goToDeleteProductItem(item)"
                                :disabled="
                                  oldstatus == 'ยกเลิก' || oldstatus == 'ออกบิล'
                                "
                              >
                                <i class="bi bi-trash3-fill"></i>
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <hr style="color: LightGrey" />

            <div class="ps-9 pb-5" style="font-size: 16px; color: grey">
              {{ $t("numberofproduct") }}
              <b style="color: black">{{ productItems.length }}</b> รายการ
            </div>
          </div>
        </div>

        <div class="col-sm-4">
          <div class="card shadow-sm classdetail">
            <div class="card-body pb-2">
              <!-- -->
              <div class="row text-sm-start d-flex justify-content-center mb-4">
                <div class="row" style="color: grey">
                  <div class="col-sm-6 mt-4 text-start" style="font-size: 16px">
                    {{ $t("totalvaluediscount") }}
                  </div>

                  <div class="col-sm-6 mt-4 text-end" style="font-size: 16px">
                    {{ parseFloat(form.total_price_before_vat).toFixed(2) }}
                  </div>
                </div>

                <hr style="color: LightGrey" class="mt-4 mb-0 pb-0" />

                <div class="row" style="color: grey">
                  <div class="col-sm-6 mt-4 text-start" style="font-size: 16px">
                    {{ $t("totalvalueafterdiscount") }}
                  </div>

                  <div class="col-sm-6 mt-4 text-end" style="font-size: 16px">
                    {{ parseFloat(form.total_price_summary).toFixed(2) }}
                  </div>
                </div>

                <hr style="color: LightGrey" class="mt-4 mb-0 pb-0" />
                <div class="row" v-if="displayDiscount.length > 0">
                  <div class="col-sm-12 textdiscount mt-4 text-start">
                    <u>
                      <b class="textdiscount" style="color: black">
                        Discount Model</b
                      >
                    </u>
                  </div>
                </div>
                <div
                  class="row"
                  style="color: grey; font-weight: 400"
                  v-if="displayDiscount.length > 0"
                  v-for="item in displayDiscount"
                  :key="item"
                >
                  <div class="col-sm-9 textdiscount mt-4 text-start">
                    <u>
                      <b class="textdiscount">
                        {{ item.discountModel.code }} -
                        {{ item.discountModel.name }}</b
                      >
                    </u>
                  </div>
                  <div class="col-sm-3 textdiscount mt-4 text-end">
                    <span>{{ item.discountAmt }}</span>
                    <span v-if="item.discountType == 'percent'"> %</span>
                    <span v-else> บาท</span>
                  </div>
                  <div class="col-sm-6 textdiscount mt-4 text-start">
                    {{ item.productDiscount.item_name }}
                  </div>

                  <div class="col-sm-6 textdiscount mt-4 text-end">
                    <!-- {{ item.discountAmt }} {{ item.discountType }} -->
                    <span>
                      -{{
                        checkDiscountPriority({
                          cost: item.productDiscount.item_price,
                          value: item.discountAmt,
                          type: item.discountType,
                          item_amt: item.productDiscount.item_amt,
                        })
                      }}
                      บาท
                    </span>
                  </div>
                  <!-- <div
                    class="col-sm-6 mt-4 text-start"
                    style="font-size: 16px"
                    v-if="displayDiscount.length > 0"
                  >
                    มูลค่าส่วนลดท้ายบิล
                  </div>
                  <div
                    class="col-sm-6 mt-4 text-end"
                    style="font-size: 16px"
                    v-if="displayDiscount.length > 0"
                  >
                    {{ this.form.discount_value }} บาท
                  </div> -->
                </div>

                <!-- ส่วนลดท้ายบิล -->
                <hr style="color: LightGrey" class="mt-4 mb-0 pb-0" />
                <div
                  class="row"
                  style="color: grey; font-weight: 400"
                  v-if="customerDetails.discount"
                >
                  <div class="col-sm-12 textdiscount mt-4 text-start">
                    <u>
                      <b class="textdiscount" style="color: black">
                        ส่วนลดท้ายบิล</b
                      >
                    </u>
                  </div>
                  <div
                    class="col-sm-6 mt-4 text-start"
                    style="font-size: 16px"
                    v-if="customerDetails.discount"
                  >
                    {{ customerDetails.discount.discount_name }}
                  </div>
                  <div
                    class="col-sm-6 mt-4 text-end"
                    style="font-size: 16px"
                    v-if="customerDetails.discount"
                  >
                    {{ customerDetails.discount.discount_price }} %
                  </div>
                  <div
                    class="col-sm-6 mt-4 text-start"
                    style="font-size: 16px"
                    v-if="customerDetails.discount"
                  >
                    มูลค่าส่วนลดท้ายบิล
                  </div>
                  <div
                    class="col-sm-6 mt-4 text-end"
                    style="font-size: 16px"
                    v-if="customerDetails.discount"
                  >
                    {{ form.discount_value }} บาท
                  </div>

                  <hr style="color: LightGrey" class="mt-4 mb-4 pb-0" />
                </div>
                <div class="row" style="color: grey; font-weight: 600" v-else>
                  <div
                    class="col-sm-6 mt-4 text-start"
                    style="font-size: 16px"
                    v-if="customerDetails.discount"
                  >
                    ไม่มีส่วนลด
                  </div>
                  <div
                    class="col-sm-6 mt-4 text-end"
                    style="font-size: 16px"
                    v-if="customerDetails.discount"
                  >
                    -
                  </div>
                </div>

                <div class="row" style="color: grey">
                  <div class="col-sm-6 mt-4 text-start" style="font-size: 16px">
                    {{ $t("totalbeforevat") }}
                  </div>

                  <div class="col-sm-6 mt-4 text-end" style="font-size: 16px">
                    {{ parseFloat(form.total_price_summary).toFixed(2) }}
                  </div>
                </div>
                <hr style="color: LightGrey" class="mt-4 mb-0 pb-0" />

                <div class="row" style="color: grey">
                  <div class="col-sm-6 mt-4 text-start" style="font-size: 16px">
                    {{ $t("tax") }}
                  </div>

                  <div class="col-sm-6 mt-4 text-end" style="font-size: 16px">
                    {{ parseFloat(form.vat).toFixed(2) }}
                  </div>
                </div>
                <hr style="color: LightGrey" class="mt-7 mb-3 pb-0" />

                <div
                  class="row pb-4"
                  style="
                    background-color: LightGrey;
                    border-radius: 7px;
                    font-size: 20px;
                    font-weight: bold;
                  "
                >
                  <div class="col-sm-6 mt-4 text-start">
                    {{ $t("totalvalue") }}
                  </div>

                  <div class="col-sm-6 mt-4 text-end" style="font-size: 16px">
                    {{ parseFloat(form.final_price).toFixed(2) }}
                  </div>
                </div>

                <hr style="color: LightGrey" class="mt-7 mb-3 pb-0" />

                <div class="row" style="color: grey" v-if="isCredit == false">
                  <div class="col-sm-4 mt-4 text-start" style="font-size: 16px">
                    <span>{{ $t("amount") }}</span>
                  </div>

                  <div class="col-sm-8 mt-4 text-end" style="font-size: 16px">
                    <input
                      v-model="form.cash"
                      @input="handleCash(form.cash)"
                      class="form-control text-end"
                      type="number"
                      min="0"
                      oninput="if(Number(this.value) < Number(this.min)) this.value = this.min;"
                      :disabled="isView"
                    />
                    <a
                      @click="
                        form.cash = parseFloat(form.final_price).toFixed(2)
                      "
                      style="font-size: 12px"
                      class="btn-active-light-primary"
                      ><u>{{ $t("fullamount") }}</u></a
                    >
                  </div>
                </div>
                <div v-if="form.status !== 'ยกเลิก'">
                  {{ $t("status") }}
                </div>
                <select
                  v-model="form.status"
                  class="form-select"
                  v-if="form.status !== 'ยกเลิก'"
                >
                  <option
                    value="รอดำเนินการ"
                    :disabled="oldstatus == 'ออกบิล' || oldstatus == 'ยกเลิก'"
                  >
                    รอดำเนินการ
                  </option>
                  <option value="ออกบิล" :disabled="oldstatus == 'ยกเลิก'">
                    ออกบิล
                  </option>
                </select>
                <hr style="color: LightGrey" class="mt-7 mb-0 pb-0" />
                <div v-if="isCredit == false">
                  <div class="row" style="color: grey">
                    <div
                      class="col-sm-6 mt-4 text-start"
                      style="font-size: 16px"
                    >
                      {{ $t("change") }}
                    </div>

                    <div
                      class="col-sm-6 mt-4 text-end"
                      style="font-size: 16px; font-weight: bold; color: black"
                    >
                      {{ parseFloat(form.change).toFixed(2) }}
                    </div>
                  </div>
                </div>
                <button
                  @click="handleCancel('cancel')"
                  type="button"
                  class="btn btn-lg btn-light btn-active-color-danger mt-5 btn-block"
                  style="font-size: 20px; font-weight: bold"
                  v-if="oldstatus !== 'ยกเลิก'"
                >
                  {{ $t("cancelsale") }}
                </button>

                <div class="col-12">
                  <button
                    v-if="oldstatus == 'ออกบิล'"
                    @click="handlePrint()"
                    type="button"
                    class="btn btn-lg btn-light btn-warning btn-block mt-5 col-sm-5"
                    style="font-size: 16px; font-weight: bold; float: left"
                  >
                    {{ $t("reprint") }}
                  </button>
                  <div
                    v-if="form.status !== 'ยกเลิก' && oldstatus !== 'ออกบิล'"
                  >
                    <button
                      v-if="isCredit == false"
                      @click="handleApprove('submit')"
                      type="button"
                      class="btn btn-lg btn-light btn-success btn-block mt-5 col-sm-5"
                      style="font-size: 16px; font-weight: bold; float: right"
                    >
                      <!-- บันทึกแบบรวดเร็ว -->
                      {{ $t("confirm") }}
                    </button>
                    <button
                      v-if="isCredit == true"
                      @click="handlePreorder('Pre-order', '')"
                      type="button"
                      class="btn btn-lg btn-light btn-success btn-block mt-5 col-sm-5"
                      style="font-size: 16px; font-weight: bold; float: right"
                    >
                      <i class="fa fa-credit-card fs-2 me-2"></i>
                      {{ $t("save") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div v-if="isExport">
      <pdfFile :dataExport="dataExport" @generatedFile="generatedFile" />
    </div>

    <DialogConfirmByPass
      :dialogConfirmByPass="dialogConfirmByPass"
      :loadingUpdate="loadingUpdate"
      @submitConfirmPass="submitConfirmPass"
      @closeDialogConformByPass="closeDialogConformByPass"
    />
    <DialogSearchCustomer
      :dialogSearchCustomer="dialogSearchCustomer"
      @closeDialogSearchCustomer="closeDialogSearchCustomer"
      @submitSearchCustomer="submitSearchCustomer"
    />
    <DialogSearchProduct
      :dialogSearchProduct="dialogSearchProduct"
      :priceListId="priceListId"
      :customerDetails="customerDetails"
      @closeDialogSearchProduct="closeDialogSearchProduct"
      @submitSearchProduct="submitSearchProduct"
    />
  </div>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";

// import saleApi from "@/api/sale/";
import transactionApi from "@/api/transaction/";
import customerAPI from "@/api/customer/";
import whApi from "@/api/warehouse/";

import Loader from "./components/loaderPOS.vue";
import DialogConfirmByPass from "./components/dialogConfirmByPass.vue";
import DialogSearchCustomer from "./components/dialogSearchCustomer.vue";
import DialogSearchProduct from "./components/dialogSearchProduct.vue";

import { Decode, Encode } from "@/services";
import Multiselect from "@vueform/multiselect";
import pdfFileSmall from "../../components/files/receiptFile.vue";
import pdfFileBig from "../../components/files/invoiceFile.vue";
import pdfFile from "../../components/files/invoiceFile.vue";
import promotionAPI from "@/api/promotion/";
import userApi from "@/api/user/";

export default {
  components: {
    DialogConfirmByPass,
    DialogSearchCustomer,
    DialogSearchProduct,
    Loader,
    Multiselect,
    pdfFileBig,
    pdfFileSmall,
    pdfFile,
  },
  setup() {
    document.title = "J&N | POS";
  },
  data: () => ({
    donut: null,
    loading: false,
    loadingUpdate: false,
    loadingAddPd: false,
    loadingAddCs: false,

    item_amt: 1,

    searchProduct: null,
    searchCustomer: "",
    searchBy: "",

    customerDetails: "",

    isSubmit: false,
    dialogConfirmByPass: false,
    dialogSearchCustomer: false,
    dialogSearchProduct: false,
    priceListId: "",

    form: {
      total_price_before_vat: 0.0,
      vat: 0.0,
      total_price_summary: 0.0,
      final_price: 0.0,
      cash: 0.0,
      change: 0.0,
      discount_value: 0.0,
      after_discount_last_bill: 0.0,
    },

    ccsSelect: `background-size: 16px 12px;background-position: right 1rem center;background-repeat: no-repeat;background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%237E8299' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e")`,
    customers: [],
    products: [],
    options: ["Batman", "Robin", "Joker"],
    productsInPut: [],

    productItems: [],
    isView: false,
    amount: null,
    searchListProduct: [],
    searchListCustomer: [],
    isCredit: false,

    isExport: false,
    dataExport: [],
    loadingExport: false,
    indexExport: "",
    displayDiscount: [],
    sumDiscountModel: 0,
    userData: [],
    productData: [],
    oldstatus: "",
  }),

  watch: {
    // searchProduct(val) {
    //   console.log("คำค้นหา", val);
    //   this.addProductItems(val);
    // },
    searchCustomer(val) {
      console.log("customers", val);
      this.addCustomer(val);
    },
  },

  async created() {
    await this.getAllCustomer();
    await this.getAllUser();
    await this.getAllWarehouse();
    let id = this.$route.query.id;
    await this.getTransaction(id);
    this.isView = true;
  },

  methods: {
    handleCancel() {
      Swal.fire({
        icon: "question",
        title: `ต้องการยกเลิกการขายใช่หรือไม่`,
        confirmButtonText: `${this.$t("ok")} `,
        cancelButtonText: `${this.$t("cancel")} `,
        showCancelButton: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          let companyId = localStorage.getItem("companyId");
          let responseData = [];
          let userId = Decode.decode(localStorage.getItem("boss"));

          this.form.products = this.productItems;

          this.form = {
            ...this.form, //product
            transaction_number: this.form.transaction_number, //sale
            customerId: this.customerDetails.id, //customer
            companyId: companyId, //บริษัท
            warehouseId: localStorage.getItem("warehouseId"), //บริษัท
            userId: userId, //sale
            vat: this.form.vat, // ภาษี
            discount: this.customerDetails.discount
              ? this.customerDetails.discount.discount_price * 1
              : 0, // ส่วนลดลูกค้า
            final_price: this.form.final_price, // ราคารวมสุทธิ
            cash: this.form.cash, // รับเงินสด
            change: this.form.change, // เงินทอน
            payment_by: "Pre-order",
            status: "ยกเลิก",
            discounts: this.displayDiscount,
            sumDiscountModel: this.sumDiscountModel,
          };
          // console.log("final data", this.form);
          try {
            responseData = await transactionApi.transaction.update(
              this.$route.query.id,
              this.form
            );
          } catch (error) {}
          if (responseData.response_status === "SUCCESS") {
            // this.updateProduct();
            console.log("submit", responseData.data);
            Swal.fire({
              icon: "success",
              title: "Order Successfully !",
              showConfirmButton: true,
              confirmButtonText: "ตกลง",
              timer: 4000,
            }).then((result) => {
              window.location.reload();
              // this.clearForm();
            });
          }
        }
      });
    },
    async getTransaction(id) {
      let responseData = [];
      try {
        responseData = await transactionApi.transaction.getOne(id);
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        let result = responseData.data;
        this.oldstatus = result.status;
        this.form.status = result.status;
        this.form.transaction_number = result.transaction_number;
        this.form.van = result.van;
        this.form.vanId = result.van.id;
        this.form.invoice_number = result.invoice_number;
        this.dataItemsShow = result.products;
        ////
        this.searchCustomer = result.customer.name;
        await this.addCustomer(this.searchCustomer);
        await this.saveCustomer();
        // this.customerDetails = this.searchListCustomer[0];
        console.log("result", result);
        result.products.forEach((element) => {
          this.productItems.push({
            id: element.product.id,
            item_code: element.product.code,
            item_name: element.product.name,
            item_amt: element.item_amt * 1,
            item_price: element.item_price * 1,
            item_unit: element.item_unit,
          });
        });
        this.sumPayout();
      }
    },
    async getAllWarehouse() {
      let responseData = [];
      try {
        responseData = await whApi.warehouse.getAll();
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.warehouses = responseData.data;
      }
    },
    submitSearchCustomer(val) {
      this.searchCustomer = val.code;
      this.customerDetails = val;
      // this.saveCustomer();
      this.dialogSearchCustomer = false;
    },
    submitSearchProduct(val) {
      // console.log("val", val);
      // this.searchProduct = val.name;
      this.enterdialog();
      this.productData = val;
      // addProductItems
      // this.searchProduct = val.code;
      // this.customerDetails = val;
      // let productId = val.id;
      // val.productPrices.forEach((element) => {
      //   if (element.default == true) {
      //     val.productPrices = element;
      //   }
      // });
      // this.productItems.push({
      //   id: productId,
      //   item_code: val.code,
      //   item_name: val.name,
      //   item_amt: amount * 1,
      //   item_price: val.productPrices.unit_price * 1,
      //   item_unit: val.productPrices.productUnit
      //     ? val.productPrices.productUnit.name
      //     : "-",
      // });
      this.dialogSearchProduct = false;
    },
    openDialogSearchCustomer() {
      this.dialogSearchCustomer = true;
    },
    openDialogSearchProcuct() {
      if (!this.oldstatus == "ยกเลิก") {
        this.dialogSearchProduct = true;
        this.priceListId = this.customerDetails.priceListId;
        this.customerDetails = this.customerDetails;
      }
    },
    openDialogSearchProduct() {
      console.log("openDialogSearchProduct");
    },
    async getAllUser() {
      this.userId = Decode.decode(localStorage.getItem("boss"));
      let responseData = [];

      try {
        responseData = await userApi.user.getOne(this.userId);
      } catch (error) {
        console.log(error);
      }

      if (responseData["response_status"] === "SUCCESS") {
        this.userData = responseData["data"];
        // this.userName = responseData["data"]["firstName"];
      }
    },
    checkDiscountPriority(item) {
      // cost:15
      // type: "percent"
      // value: 15
      // item_amt: 12
      let discount = 0;
      if (item.type == "percent") {
        discount = (item.item_amt * item.cost * item.value) / 100;
      } else {
        discount = item.value;
      }

      return discount;
    },

    enterdialog() {
      this.amount = null;
      document.getElementById("button-open-dialog").click();
      setTimeout(() => {
        this.$refs.refamount.focus();
      }, 500);
    },
    async getAllCustomer() {
      this.loading = true;
      let responseData = [];
      let companyId = localStorage.getItem("companyId");
      try {
        responseData = await customerAPI.customer.getAll(companyId);
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.customers = responseData.data;
        this.loading = false;
      } else {
        this.loading = false;
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ดึงข้อมูลไม่สำเร็จ กรุณาตรวจสอบ SERVER",
        });
      }
    },
    async getAllProduct() {
      this.loading = true;
      let responseData = [];
      try {
        responseData = await whApi.product.getAll();
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.products = responseData.data;
        this.loading = false;
      } else {
        this.loading = false;
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ดึงข้อมูลไม่สำเร็จ กรุณาตรวจสอบ SERVER",
        });
      }
    },

    cancel() {
      this.$router.go(-1);
    },
    goBack() {
      // this.$router.go(-1);
      window.close();
    },

    async addProductItems(searchProduct) {
      // document.getElementById("button-open-dialog").click();
      // console.log("addProductItems", searchProduct, this.amount);
      let responseData = [];
      let result = [];
      try {
        responseData = await whApi.product.search({
          search: searchProduct,
          warehouseId: localStorage.getItem("warehouseId"),
        });
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.searchListProduct = responseData.data;
        // result = responseData.data;
        // result.filter((element) => {
        //   if (element.active_flag == true) {
        //     this.searchListProduct.push(element);
        //   }
        // });
      }
    },

    async saveCustomer() {
      let donut = document.getElementById("datalistCustomer11");
      this.customerDetails = this.searchListCustomer[0];
      console.log("this.customerDetails", this.customerDetails);
      if (this.customerDetails.business_type == "Pre order") {
        this.isCredit = true;
      } else if (this.customerDetails.business_type == "Cash Van") {
        this.isCredit = false;
      }
      this.sumPayout();
      // <!-- Pre order -->
      // <!-- Cash Van -->
    },
    async saveProduct(amount) {
      // let donut = document.getElementById("datalistOptions11");
      // let productId = this.searchListProduct[0].id;
      // let arr = [];

      // // ต้อง getoneตรงนี้ เพื่อ performanceหลังจากค้นหา
      // let responseData = [];
      // try {
      //   responseData = await whApi.product.getOne(productId);
      // } catch (error) {
      //   console.log(error);
      // }
      // if (responseData.response_status === "SUCCESS") {
      //   arr = responseData.data;
      //   console.log("arr  getone", arr);
      //   arr.productPrices.forEach((element) => {
      //     if (element.default == true) {
      //       arr.productPrices = element;
      //     }
      //   });
      // }
      //
      // this.searchListProduct[0].productPrices.forEach((element) => {
      //   if (element.default == true) {
      //     arr.productPrices = element;
      //   }
      // });
      //////////////////////////////////////////////
      let arr = this.productData;
      this.productItems.push({
        id: arr.productId,
        item_code: arr.productCode,
        item_name: arr.productName,
        item_amt: amount * 1,
        item_price: arr.productPrice * 1,
        item_unit: arr.unitName,
      });
      console.log("productItems", this.productItems);
      this.sumPayout();
      document.getElementById("close-btn").click();
    },

    async addCustomer(searchCustomer) {
      let responseData = [];
      try {
        responseData = await customerAPI.customer.searchpos({
          search: searchCustomer,
          companyId: localStorage.getItem("companyId"),
          branchId: localStorage.getItem("branchId"),
        });
      } catch (error) {
        console.log(error);
      }
      console.log("responseData.data", responseData.data);
      if (responseData.response_status === "SUCCESS") {
        this.searchListCustomer = responseData.data;

        // this.types = responseData.data;
        // this.loading = false;
      }
    },

    goToDeleteProductItem(item) {
      Swal.fire({
        icon: "question",
        html: `<span style="font-size: 16px">ต้องการลบสินค้ารายการ <b style="font-size: 18px">${item.item_name}</b> หรือไม่ ?</span>`,
        showCancelButton: true,
        width: 450,
      }).then((result) => {
        if (result.isConfirmed) {
          this.confirmDeleteProductItem(item);
        }
      });
    },
    confirmDeleteProductItem(item) {
      const indexOfItem = this.productItems.indexOf(item);
      this.productItems.splice(indexOfItem, 1);
      this.sumPayout();
    },

    addQtyByIndex(index, item_amt) {
      // ...this.productItems[index],
      // this.productItems[index] = {
      //   id: this.searchListProduct[0].id,
      //   item_amt: item_amt,
      // };
      this.sumPayout();
      // this.handleCash(this.form.cash);
    },

    async sumPayout() {
      /////////////คำนวน
      let companyId = localStorage.getItem("companyId");
      let responseData = [];
      let userId = Decode.decode(localStorage.getItem("boss"));

      this.form.products = this.productItems;
      this.form = {
        ...this.form, //product
        // products: this.productItems,
        customerId: this.customerDetails.id, //customer
      };

      console.log("this.form", this.form);
      try {
        responseData = await promotionAPI.discount.checkDiscountModel(
          this.form
        );
      } catch (error) {
        console.log(error);
      }
      // console.log("discount_discount_discount", responseData.data);
      let total = 0;
      let vat = 0;
      //คำนวนใหม่
      this.productItems.forEach((element) => {
        if (element.item_price && element.item_amt) {
          total +=
            parseFloat(element.item_price) * parseFloat(element.item_amt);
        }
      });
      this.displayDiscount = responseData.data;
      /////////
      this.form.total_price_before_vat = total; //มูลค่าก่อนรวมส่วนลด

      // ุคำนวนเก่าแบบแยก vat
      // this.productItems.forEach((element) => {
      //   if (element.item_price && element.item_amt) {
      //     total += parseFloat(element.item_price) * parseFloat(element.item_amt);
      //     vat +=
      //       (parseInt(element.item_price) * parseInt(element.item_amt) * 7) /
      //       100;
      //   }
      // });
      // this.displayDiscount = responseData.data;
      // /////////
      // this.form.total_price_before_vat = total - vat;
      // this.form.vat = vat;
      // this.form.total_price_summary = total; //มูลค่าก่อนรวมส่วนลด
      if (this.customerDetails.discount) {
        this.form.discount_value =
          (total * this.customerDetails.discount.discount_price) / 100;
      }
      ///เช็ค discountModel
      this.sumDiscountModel = 0;
      this.displayDiscount.forEach((element) => {
        let discount = 0;
        if (element.discountModel) {
          if (element.discountType == "percent") {
            discount =
              (element.productDiscount.item_amt *
                element.productDiscount.item_price *
                element.discountAmt) /
              100;
            // console.log("discount", discount);
          } else {
            discount = element.discountAmt;
          }
          this.sumDiscountModel += discount * 1;
        }
      });
      //
      this.form.total_price_summary = parseFloat(
        (total - this.form.discount_value - this.sumDiscountModel).toFixed(2)
      );

      this.form.vat = parseFloat(
        ((this.form.total_price_summary * 7) / 100).toFixed(2)
      );
      this.form.after_discount_last_bill = parseFloat(total + this.form.vat);

      this.form.final_price = parseFloat(
        (this.form.total_price_summary + this.form.vat).toFixed(2)
      ); //มูลค่ารวมสุทธิ บวก vat

      //ไม่เอา

      // this.form.final_price = this.customerDetails.discount.discount_price
      //   ? total -
      //     this.form.final_price *
      //       (parseFloat(this.customerDetails.discount.discount_price) / 100)
      //   : total;
    },

    handleCash(val) {
      if (val && val > this.form.final_price) {
        this.form.change = parseFloat(val) - parseFloat(this.form.final_price);
      }
    },
    handlePrint() {
      this.goToExport(this.form);
    },
    handleApprove(item) {
      if (!this.searchCustomer) {
        Swal.fire({
          icon: "warning",
          title: "ชำระเงินไม่สำเร็จ !",
          text: "กรุณาเลือกผู้ซื้อ",
        });
      } else if (this.productItems.length == 0) {
        Swal.fire({
          icon: "warning",
          title: "ชำระเงินไม่สำเร็จ !",
          text: "กรุณาเลือกสินค้าอย่างน้อย 1 รายการ",
        });
      } else if (this.form.total_price_summary === 0) {
        Swal.fire({
          icon: "warning",
          title: "ชำระเงินไม่สำเร็จ !",
          text: "มีรายการสินค้าไม่ได้กรอก item_amt",
        });
      } else if (this.form.cash === 0) {
        Swal.fire({
          icon: "warning",
          title: "ชำระเงินไม่สำเร็จ !",
          text: "กรุณากรอกช่องรับเงินสด",
        });
      } else if (this.form.cash < this.form.final_price) {
        Swal.fire({
          icon: "warning",
          title: "ชำระเงินไม่สำเร็จ !",
          text: "มูลค่าเงินสดต้องมากกว่ามูลค่ารวมสุทธิ",
        });
      } else {
        this.submit("CASH VAN", "รอจัดส่ง");
        // Pre-order
        // คอนเฟิร์มออเดอร์
        // รอจัดส่ง
        // ออกบิล*

        //   // this.dialogConfirmByPass = true;
      }
    },

    handlePreorder(item) {
      if (!this.searchCustomer) {
        Swal.fire({
          icon: "warning",
          title: "ชำระเงินไม่สำเร็จ !",
          text: "กรุณาเลือกผู้ซื้อ",
        });
      } else if (this.productItems.length == 0) {
        Swal.fire({
          icon: "warning",
          title: "ชำระเงินไม่สำเร็จ !",
          text: "กรุณาเลือกสินค้าอย่างน้อย 1 รายการ",
        });
      } else if (this.form.total_price_summary === 0) {
        Swal.fire({
          icon: "warning",
          title: "ชำระเงินไม่สำเร็จ !",
          text: "มีรายการสินค้าไม่ได้กรอก item_amt",
        });
      } else {
        this.submit("Pre-order", "รอดำเนินการ");
        //   // this.dialogConfirmByPass = true;
      }
    },
    async submitConfirmPass(isApprove) {
      // this.submit();
      this.dialogConfirmByPass = false;
    },
    closeDialogConformByPass() {
      this.dialogConfirmByPass = false;
    },
    closeDialogSearchCustomer() {
      this.dialogSearchCustomer = false;
    },
    closeDialogSearchProduct() {
      this.dialogSearchProduct = false;
    },
    //submithere
    //วาป
    async submit(payment_by) {
      Swal.fire({
        icon: "question",
        title: `${this.$t("confirm")} ${this.$t("ornot")} ?`,
        confirmButtonText: `${this.$t("ok")} `,
        cancelButtonText: `${this.$t("cancel")} `,
        showCancelButton: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          if (this.form.status == "ยกเลิก") {
          } else {
            let companyId = localStorage.getItem("companyId");
            let responseData = [];
            let userId = Decode.decode(localStorage.getItem("boss"));

            this.form.products = this.productItems;

            this.form = {
              ...this.form, //product
              transaction_number: this.form.transaction_number, //sale
              customerId: this.customerDetails.id, //customer
              companyId: companyId, //บริษัท
              userId: userId, //sale
              warehouseId: localStorage.getItem("warehouseId"), //sale
              vat: this.form.vat, // ภาษี
              discount: this.customerDetails.discount
                ? this.customerDetails.discount.discount_price * 1
                : 0, // ส่วนลดลูกค้า
              final_price: this.form.final_price, // ราคารวมสุทธิ
              cash: this.form.cash, // รับเงินสด
              change: this.form.change, // เงินทอน
              payment_by: payment_by,
              status: this.form.status,
              discounts: this.displayDiscount,
              sumDiscountModel: this.sumDiscountModel,
            };
            console.log("final data", this.form);
            try {
              responseData = await transactionApi.transaction.update(
                this.$route.query.id,
                this.form
              );
            } catch (error) {}
            if (responseData.response_status === "SUCCESS") {
              if (this.form.status == "รอดำเนินการ") {
                Swal.fire({
                  icon: "success",
                  title: `${this.$t("editsuccess")} `,
                  showConfirmButton: true,
                  confirmButtonText: "ตกลง",
                  timer: 4000,
                }).then((result) => {
                  window.location.reload();
                });
              } else if (this.form.status == "ออกบิล") {
                Swal.fire({
                  icon: "success",
                  title: `ออกบิลสำเร็จ !`,
                  showConfirmButton: true,
                  confirmButtonText: "ตกลง",
                  timer: 4000,
                }).then((result) => {
                  window.location.reload();
                });
              }
            } else {
              Swal.fire({
                icon: "error",
                title: responseData.message,
                showConfirmButton: true,
                confirmButtonText: "ตกลง",
              });
            }
          }
        }
      });
    },

    // update item
    async updateProduct() {
      let countArr = 0;
      for (const element of this.form.products) {
        countArr += 1;
        await this.getOneProduct(element.id, element.item_amt);
      }
      // if (
      //   countArr === this.form.products.length &&
      //   this.statusAllProcess === 1
      // ) {
      //   this.$router.push({
      //     path: "/sale/SA5",
      //     query: {},
      //   });
      // }
    },
    async getOneProduct(id, item_amt) {
      let getOneResponse = {};
      try {
        getOneResponse = await whApi.product.getOne(id);
      } catch (error) {
        console.log(error);
      }
      if (getOneResponse.response_status === "SUCCESS") {
        this.updateProductAmountRecieve(item_amt, getOneResponse.data);
      }
    },
    async updateProductAmountRecieve(item_amt, item) {
      let updateResponse = [];
      let sumQty = parseFloat(item.product_bring_qty) - parseFloat(item_amt);

      const body = {
        ...item,
        product_bring_qty: sumQty,
      };

      updateResponse = await whApi.product.update(item.id, body);
      if (updateResponse.response_status !== "SUCCESS") {
        this.statusAllProcess *= 0;

        Swal.fire({
          icon: "error",
          html: `ชำระค่าสินค้าไม่สำเร็จ !`,
          timer: 4000,
        });
      } else {
        this.statusAllProcess *= 1;
      }
    },
    // update item

    clearForm() {
      this.form = {
        // selectMainProduct: "",
        // selectSecondaryProduct: "",
        // ProductName: "",
        // detail: "",
        // barcode: "",
        // pog: "",
        // retailPrice: "",
        // proPrice: "",
        // wholesalePrice: "",
        // min: "",
        // max: "",

        cash: 0,
        change: 0,

        total_price_before_vat: 0,
        vat: 0,
        total_price_summary: 0,
        final_price: 0,
        isCredit: false,
      };

      this.searchProduct = "";
      this.searchCustomer = "";
      this.customerDetails = "";

      this.productItems = [];

      this.getAllCustomer();
    },

    // view
    async getOne() {
      if (this.$route.query.id) {
        this.loading = true;
        let responseData = [];

        try {
          responseData = await saleApi.pos.getOne(this.$route.query.id);
        } catch (error) {
          console.log(error);
        }
        if (responseData.response_status === "SUCCESS") {
          this.form = {
            ...responseData.data,
            total_price_before_vat: parseFloat(
              responseData.data.total_price_before_vat
            ),
            vat: parseFloat(responseData.data.vat),
            total_price_summary: parseFloat(
              responseData.data.total_price_summary
            ),
            final_price: parseFloat(responseData.data.final_price),
            change: parseFloat(responseData.data.change),
          };
          this.setProductByGetOne(responseData.data.pointOfSaleItems);
          this.loading = false;
        } else {
          this.loading = false;
          Swal.fire({
            icon: "error",
            title: "เกิดข้อผิดพลาด",
            text: "ดึงข้อมูลไม่สำเร็จ กรุณาตรวจสอบ SERVER",
          });
        }
      }
    },

    setProductByGetOne(item) {
      item.forEach((element) => {
        this.productItems.push({
          ...element.product,
          item_amt: element.item_amt,
        });
      });
    },

    //search customer
    async onSearchCustomer() {
      let responseSearch = [];

      responseSearch = await customerAPI.customer.searchpos(
        {
          search: this.searchCustomer,
          branchId: localStorage.getItem("branchId"),
        },
        "1"
      );

      if (
        responseSearch.response_status === "SUCCESS" &&
        responseSearch.data.length > 0
      ) {
        this.loadingAddCs = false;
        this.addCustomer(responseSearch.data);
        this.form.customer_code = responseSearch.data[0].customer_code;
        console.log(responseSearch.data);
      }
    },
    // Export file
    async goToExport(item) {
      //// dataExport ส่ง props เข้าตัวนี้
      // console.log("customerDetails", this.customerDetails);
      //วาป
      let customers = {
        address: this.customerDetails.address,
        name: this.customerDetails.name,
        code: this.customerDetails.code,
        route:
          this.customerDetails.route.code + this.customerDetails.route.name,
        tax_id: this.customerDetails.tax_id,
        tax_id_branch: this.customerDetails.tax_id_branch,
      };
      let dataExport = {
        ...item,
        customers: customers,
        products: this.productItems,
        discounts: this.displayDiscount,
        form: this.form,
        user: this.userData,
        invoiceNo: "INV20221201",
        date: new Date(),
        sumDiscountModel: this.sumDiscountModel,
      };

      Swal.fire({
        icon: "question",
        title: `กรุณาเลือกประเภทเอกสาร`,
        confirmButtonText: `พิมพ์ต้นฉบับ`,
        cancelButtonText: `พิมพ์สำเนา`,
        showCancelButton: true,
        showConfirmButton: true,
        showCloseButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#50CD89",
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.dataExport = dataExport;
          this.dataExport.isCopy = false;
          this.loadingExport = true;
          this.isExport = true;
        } else if (result.isDismissed) {
          this.dataExport = dataExport;
          this.dataExport.isCopy = true;
          this.loadingExport = true;
          this.isExport = true;
        }
      });
    },
    generatedFile() {
      this.isExport = false;
    },
    hoverExportBtn() {
      this.loadingExport = false;
      this.isExport = false;
    },
    // Export file
  },
};
</script>
<style>
.vs__dropdown-toggle {
  border-radius: 0px 5px 5px 0px;

  height: 37.5px;
}
.textdiscount {
  font-size: 14px;
}
</style>
<style src="@vueform/multiselect/themes/default.css"></style>